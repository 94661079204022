import React from "react";
import { Modal, ModalBody, Spinner, ModalHeader } from "reactstrap";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { mintProgressReset } from "../../../redux/contracts";

const MintProgressModal = (props, context) => {
  const dispatch = useDispatch();
  // const [modalOpen, setModalOpen] = useState(false);
  const mintingInProgress = useSelector(
    (state) => state?.contracts?.mintingInProgress
  );
  const awaitingTransactionConfirmation = useSelector(
    (state) => state?.contracts?.awaitingTransactionConfirmation
  );

  return (
    <Modal
      isOpen={awaitingTransactionConfirmation || mintingInProgress}
      toggle={() => dispatch(mintProgressReset())}
    >
      <ModalHeader toggle={() => dispatch(mintProgressReset())}>
        <Spinner color={mintingInProgress ? "warning" : "success"} />{" "}
        {mintingInProgress
          ? "Minting in Progress"
          : "Please confirm your Transaction"}
      </ModalHeader>
      <ModalBody>
        <p className="text-success font-weight-bold">
          {mintingInProgress && (
            <>
              {context.t(
                "Your transaction was sent to the blockchain and is about to be minted."
              )}
            </>
          )}
          {!mintingInProgress && (
            <>
              {context.t(
                "Please follow your wallet instructions to confirm the transaction."
              )}
            </>
          )}
        </p>
        {mintingInProgress && (
          <p className="text-info">
            {context.t(
              "You can close this modal at any time - it does not affect your transaction. We will close the modal for you once we see that you received a token."
            )}
            <br />
            <strong>
              {context.t(
                "Please check your wallets transaction history for your actual transaction status!"
              )}
            </strong>
          </p>
        )}
        {!mintingInProgress && (
          <p className="text-info">
            {context.t(
              "This modal is just for your information and has no influence on your actual minting status."
            )}
          </p>
        )}
      </ModalBody>
    </Modal>
  );
};

/**  define proptype for the 'translation' function  */
MintProgressModal.contextTypes = {
  t: PropTypes.func,
};

export default MintProgressModal;
