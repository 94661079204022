import React from "react";
import PropTypes from "prop-types";
import { isServer } from "../../redux/store";
import { useSelector } from "react-redux";
import { Alert } from "reactstrap";
import { getContractAttributes } from "../../redux/contracts";

const NetworkBar = ({ contractByAddress }, context) => {
  let compResponse = null;

  const contractData = useSelector((state) =>
    getContractAttributes(state, contractByAddress)
  );

  if (
    !isServer &&
    !contractData?.network &&
    window.ethereum &&
    window.ethereum.networkVersion &&
    typeof window.ethereum.networkVersion === "string" &&
    window.ethereum.networkVersion !== (0x01).toString(16)
  ) {
    compResponse = (
      <Alert color="danger">
        <div>
          {context.t(
            "You are currently not connected to the Ethereum Network. Please change your network in metamask."
          )}
        </div>
      </Alert>
    );
  }
  if (
    !isServer &&
    window?.ethereum?.networkVersion &&
    typeof window.ethereum.networkVersion === "string" &&
    contractData?.network === "rinkeby" &&
    window.ethereum.networkVersion !== (0x4).toString(16)
  ) {
    compResponse = (
      <Alert color="danger">
        <div>
          {context.t(
            "You are currently not connected to the Rinkeby Test-Network. Please change your network in metamask."
          )}
        </div>
      </Alert>
    );
  }
  if (
    !isServer &&
    window?.ethereum?.networkVersion &&
    typeof window.ethereum.networkVersion === "string" &&
    contractData?.network === "ethereum" &&
    window.ethereum.networkVersion !== (0x1).toString(16)
  ) {
    compResponse = (
      <Alert color="danger">
        <div>
          {context.t(
            "You are currently not connected to the Ethereum Network. Please changeyour network in metamask."
          )}
        </div>
      </Alert>
    );
  }
  if (
    !isServer &&
    window?.ethereum?.networkVersion &&
    typeof window.ethereum.networkVersion === "string" &&
    contractData?.network === "polygon" &&
    window.ethereum.networkVersion !== (0x89).toString(16)
  ) {
    compResponse = (
      <Alert color="danger">
        <div>
          {context.t(
            "You are currently not connected to the Polygon Network. Please change your network in metamask."
          )}
        </div>
      </Alert>
    );
  }
  return compResponse;
};

/**  define proptype for the 'translation' function  */
NetworkBar.contextTypes = {
  t: PropTypes.func,
};

NetworkBar.propTypes = {
  contractByAddress: PropTypes.num,
};

export default NetworkBar;
