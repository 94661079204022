/**
 * The PageHead container
 */
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

/**
 * PageHead component used as app default landing page
 *
 */

const PageHead = (props) => {
  const { title } = props;

  return (
    <div className="d-flex mb-3">
      <h1 className="h1 mt-5">{title}</h1>
    </div>
  );
};

/**  define proptype for the 'translation' function  */
PageHead.contextTypes = {
  t: PropTypes.func,
};

PageHead.propTypes = {
  title: PropTypes.string.isRequired,
  backTarget: PropTypes.string,
};

PageHead.defaultProps = {
  backTarget: null,
};

/** export the component as redux connected component */
export default withRouter(PageHead);
