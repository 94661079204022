import React, { useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  mintingInProgressSuccess,
  genericAbiWrite,
  mintError,
  transactionSuccess,
} from "../../../../redux/contracts";
import { getMintTierAttributes } from "../../../../redux/mintTiers";

const MintButtons = (
  { mintTierId, mintPrice, disabled, availableAmount, alreadyMintedAmount },
  context
) => {
  const dispatch = useDispatch();
  const { account } = useWeb3React();
  const maxAmount = availableAmount - alreadyMintedAmount;

  const mintTier = useSelector((state) =>
    getMintTierAttributes(state, mintTierId)
  );

  const [tokenCounter, setTokenCounter] = useState(1);
  const decrement = () => {
    if (tokenCounter > 1) {
      setTokenCounter(tokenCounter - 1);
    }
  };
  const increment = () => {
    if (tokenCounter < maxAmount) {
      setTokenCounter(tokenCounter + 1);
    }
  };

  const handleMint = async () => {
    console.log("mint price", (tokenCounter * mintPrice).toString());
    dispatch(
      genericAbiWrite({
        functionAbi: mintTier?.mint_abi,
        contractAddress: mintTier?.contract_address,
        account: account,
        payableValue: (tokenCounter * mintPrice).toString(),
        genericInputs: {
          quantity: tokenCounter,
          tokenReceiver: account,
          mintpass: {
            // TODO  mintpass object should be build with signature_type
            wallet: account,
            tier: mintTier?.internal_tier_id,
          },
          mintpassSignature: "0x" + mintTier?.mint_pass_signature,
        },
        successCallbackFnc: (data) => {
          console.log("success Data", data);
          dispatch(mintingInProgressSuccess(data));
        },
        errorCallbackFnc: (data) => {
          console.log("error Data", data);
          dispatch(mintError(data));
        },
        transactionCallbackFnc: (data) => {
          console.log("transfer data", data);
          dispatch(transactionSuccess(data));
        },
      })
    );
  };

  return (
    <>
      <div className="d-flex justify-content-around px-5 py-3 text-primary">
        <Button
          className="rounded-circle"
          onClick={decrement}
          outline
          color="primary"
          disabled={tokenCounter <= 1}
          style={{ width: "45px", height: "45px" }}
        >
          -
        </Button>
        <div className="d-flex align-items-center">
          <span className="h2 text-dark">{tokenCounter}</span>
        </div>
        <Button
          className="rounded-circle"
          onClick={increment}
          color="primary"
          outline
          disabled={tokenCounter >= maxAmount}
          style={{ width: "45px", height: "45px" }}
        >
          +
        </Button>
      </div>
      <div className="my-3 d-grid gap-2">
        <Button
          className="btn rounded-pill"
          outline
          disabled={!!availableAmount && disabled}
          color="primary"
          onClick={handleMint}
        >
          {context.t("Mint")}
        </Button>
        {!!availableAmount && disabled && (
          <div className="text-dark">
            {availableAmount <= 0 ? (
              <p>{context.t("No tokens available for minting")}</p>
            ) : (
              <>
                {availableAmount - alreadyMintedAmount <= 0 ? (
                  <p>{context.t("Max tokens minted")}</p>
                ) : (
                  <p>{context.t("Mint will start soon. Stay tuned!")}</p>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

/**  define proptype for the 'translation' function  */
MintButtons.contextTypes = {
  t: PropTypes.func,
};

MintButtons.propTypes = {
  // disabled: PropTypes.boolean,
  mintTierId: PropTypes.string,
  availableAmount: PropTypes.number,
  alreadyMintedAmount: PropTypes.number,
  mintPrice: PropTypes.string,
  disabled: PropTypes.bool,
};

MintButtons.defaultProps = {};

export default MintButtons;
