/**
 * The Events container
 */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Page from "../../components/Page";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Container } from "reactstrap";
import { useWeb3React } from "@web3-react/core";
import { Alert } from "reactstrap";
import { NavLink } from "react-router-dom";
import {
  listTiersForProject,
  getAvailableMintTierContracts,
} from "../../redux/mintTiers";
import { mintErrorReset } from "../../redux/contracts";
import PageHead from "../../components/PageHead";
import ScrollToTop from "../../components/Utils/Scroll/ScrollToTop";
import ContractMints from "../../components/Minting/ContractMints";
import TransactionSuccessModal from "../../components/Minting/TransactionSuccessModal";
import ErrorModal from "../../components/ErrorModal";
import MintProgressModal from "../../components/Minting/MintProgressModal/MintProgressModal-component";
const projectId = process.env.REACT_APP_PROJECT_ID;

/**
 * MintingSection container used as app default landing page
 */
const MintingSection = (props, context) => {
  const dispatch = useDispatch();
  const { account } = useWeb3React();

  const mintSuccess = useSelector((state) => state?.contracts?.mintSuccess);

  console.log("mint success", mintSuccess);

  const mintError = useSelector((state) => state?.contracts?.mintError);

  const mintErrorMessage = useSelector(
    (state) => state?.contracts?.mintErrorMessage
  );

  useEffect(() => {
    account &&
      dispatch(
        listTiersForProject({
          projectId: projectId,
          address: account,
        })
      );
    window.scrollTo(0, 0);
  }, [dispatch, projectId, account]);

  const mintTierIds = useSelector((state) => state?.mintTiers?.idsList);

  const contracts = useSelector((state) =>
    getAvailableMintTierContracts(state, mintTierIds)
  );

  return (
    <Page
      id="MintingSection"
      title={context.t("Minting Section")}
      className="bg-gradient"
    >
      <ScrollToTop />
      <Container>
        <PageHead
          title={context.t("Mint your Founders Pass")}
          backTarget={"/"}
        />

        <div className="ml-5 d-flex flex-column minting-wrapper">
          {account ? (
            <>
              {contracts.map((contractAddress) => (
                <ContractMints
                  contractAddress={contractAddress}
                  key={contractAddress}
                />
              ))}
            </>
          ) : (
            <Alert color="light" className="text-center my-5">
              <p className="fw-bold h5 text-dark">
                {context.t(
                  "Please connect your wallet before you are eligible to mint!"
                )}
              </p>
              <NavLink
                to="/onboarding/metamask"
                id="nav-connect-wallet"
                className="btn stretched-link btn-primary px-4 py-2 my-2 text-white rounded-pill"
              >
                {context.t("Connect to Wallet")}
              </NavLink>
            </Alert>
          )}
        </div>
      </Container>
      {mintSuccess && (
        <TransactionSuccessModal
          success={mintSuccess}
          redirectUrl={`/minting`}
          resourceType="token"
          action="minted"
        />
      )}
      <MintProgressModal />
      {mintError &&
        mintErrorMessage?.errorMessage !== "transaction is not defined" && (
          <ErrorModal
            error={mintError}
            errorReset={mintErrorReset}
            customErrorBody={mintErrorMessage?.errorMessage}
          />
        )}
    </Page>
  );
};

/**  define proptype for the 'translation' function  */
MintingSection.contextTypes = {
  t: PropTypes.func,
};

/** export the component as redux connected component */
export default withRouter(MintingSection);
